const firebaseConfig = {
	apiKey: 'AIzaSyB4UwgXrurDMUbwGZuQTIgglU4pejcOoNk',
	authDomain: 'thevergeghi-shop01.firebaseapp.com',
	projectId: 'thevergeghi-shop01',
	storageBucket: 'thevergeghi-shop01.appspot.com',
	messagingSenderId: '7686472466',
	appId: '1:7686472466:web:bc294ec76e2fa6e9e529cb',
	measurementId: 'G-CRPMG7252K',
}

export default firebaseConfig
